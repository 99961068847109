import UniversalCookies from 'universal-cookie'
import moment from 'moment'


class Cookies {

    static init () {
        Cookies.store = new UniversalCookies()
    }

    static get (name) {
        return Cookies.store.get(name)
    }

    static set (name, value) {
        const expires = moment().add(1, 'year').toDate()
        Cookies.store.set(name, value, {
            path: '/',
            expires,
            sameSite: 'strict'
        })
    }

    static getCookieConsentState () {
        return Cookies.get(Cookies.KEY_COOKIE_CONSENT)
    }

    static setCookieConsentState (cookieConsentState) {
        Cookies.set(Cookies.KEY_COOKIE_CONSENT, cookieConsentState)
    }

    static getJourneyId () {
        return Cookies.get(Cookies.KEY_JOURNEY_ID)
    }

    static setJourneyId (journeyId) {
        Cookies.set(Cookies.KEY_JOURNEY_ID, journeyId)
    }
}

Cookies.KEY_COOKIE_CONSENT = 'cookie-consent'
Cookies.KEY_JOURNEY_ID = 'journey'

export default Cookies

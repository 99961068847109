class Utils {
    static isDev () {
        return process.env.GATSBY_EPI_RELEASE_ENV.includes('development')
    }

    static isProd () {
        return process.env.GATSBY_EPI_RELEASE_ENV === 'production'
    }

    static getEnvironment () {
        return process.env.GATSBY_EPI_RELEASE_ENV
    }

    static shouldAllowUnpublishedPosts () {
        return Environment.isDev()
    }
}

module.exports = Utils
